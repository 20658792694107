@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: 35, 63, 140;
  --secondary: 249, 173, 66;
}

body {
  font-family: "Poppins", "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

input[type="date"]#dob {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
}
input[type="date"]#dob::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
  background-color: rgb(var(--secondary));
  color: white;
  outline: none;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 20px 0;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: rgba(var(--primary), 0.5);
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  background: rgba(var(--primary), 0.5);
  border-radius: 25px;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  border: 2px solid rgb(var(--primary));
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgb(0, 0, 0);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
}

/*// Glow Border Animation //*/

.animated-border-box,
.animated-border-box-glow {
  /* max-height: 100vw;
  max-width: 100vw; */
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  /* Border Radius */
  border-radius: 40px;
  padding: 2px;
}

.animated-border-box-glow {
  overflow: hidden;
  /* Glow Blur */
}

.animated-border-box-glow:before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 99999px;
  height: 99999px;
  background-repeat: no-repeat;
  background-position: 0 0;
  /*border color, change middle color*/
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    #97cfff,
    rgba(0, 0, 0, 0) 8%
  );
  /* change speed here */
  animation: rotate 5s linear infinite;
}
.animated-border-box:before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 99999px;
  height: 99999px;
  background-repeat: no-repeat;
  background-position: 0 0;
  /*border color, change middle color*/
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    #97cfff,
    rgba(0, 0, 0, 0) 8%
  );
  /* change speed here */
  animation: rotate2 5s linear infinite;
}
.animated-border-box:after {
  content: "";
  position: absolute;
  z-index: -2;
  /* border width */
  left: 2.5px;
  top: 2.5px;
  /* double the px from the border width left */
  width: calc(100% - 7.5px);
  height: calc(100% - 7.5px);
  /*bg color*/
  background: #091023;
  /*box border radius*/
  border-radius: 40px;
  border: 4px #3298f1 solid;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}
@keyframes rotate2 {
  100% {
    transform: translate(-50%, -50%) rotate(-1turn);
  }
}
/*// Border Animation END//*/

.vertical-text {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(calc(50% - 5px)) translateY(-50%) rotate(90deg);
  font-size: 10px;
  line-height: 1;
}
@media (min-width: 540px) {
  .vertical-text {
    font-size: 15px;
    transform: translateX(calc(50% - 7.5px)) translateY(-50%) rotate(90deg);
  }
}

.vertical-lined-text {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vertical-lined-text:before,
.vertical-lined-text:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid currentColor;
  margin-top: auto;
  margin-bottom: auto;
}
.vertical-lined-text:before {
  margin-inline-end: 4px;
}
.vertical-lined-text:after {
  margin-inline-start: 4px;
}
@media (min-width: 540px) {
  .vertical-lined-text:before {
    margin-inline-end: 10px;
  }
  .vertical-lined-text:after {
    margin-inline-start: 10px;
  }
}

.divider {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 245, 245, 0) 2%,
    rgba(50, 152, 241, 0.98) 52%,
    rgba(50, 152, 241, 1) 53%,
    rgba(255, 228, 228, 0) 95%,
    rgba(255, 255, 255, 0) 100%
  );
}

.scan-animation {
  animation: scan 3s linear infinite alternate;
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 2px;
}

@keyframes scan {
  from {
    top: calc(0% + 1.5rem);
  }
  to {
    top: calc(100% - 1.5rem);
  }
}
